// 本页面是一些固定的本地数据

// 需要创建的室长的固定 erpId
const agentCreateErpId = 'C0000002'

// 组织
const organize = [
    {
        value: '100',
        name: "上海维娜化妆品有限公司"
    },
    {
        value: '500',
        name: "上海蓓霓芬化妆品有限公司"
    }
]

// 店铺属性选择
const sotrePropertyList = [
    {
        value: '',
        name: '普通店' 
    },
    {
        value: 'weina',
        name: '专卖店' 
    },
    {
        value: 'spa',
        name: 'SPA店' 
    },
]

// 签约公司，分配团队、组
const teamList = [
    {
        value: 'G',
        name: '徐州G' 
    },
    {
        value: 'L',
        name: '徐州L' 
    },
    {
        value: 'N',
        name: '未分配' 
    },
]

// 签约公司，分配团队、组
const areaList = [
    {
        value: 'XZG',
        name: '徐州G' 
    },
    {
        value: 'XZL',
        name: '徐州L' 
    },
    {
        value: 'JN',
        name: '济南' 
    },
    {
        value: 'ZZ',
        name: '郑州' 
    },
    {
        value: 'DL',
        name: '大连' 
    },
    {
        value: 'QD',
        name: '青岛' 
    },
    {
        value: 'HF',
        name: '合肥' 
    },
    {
        value: 'QT',
        name: '其他' 
    },
]

// 销售区域
const salesArea = {
    "myxz": '徐州',
    "mydl": '大连',
    "myzz": '郑州',
    "myqd": '青岛',
    "myjn": '济南',
    "myhf": '合肥',
    "myxzl": '徐州L',
    "myxzg": '徐州G',
    // "myxz": '贸易徐州',
    // "myxz": '贸易徐州',
    // "mydl": '贸易大连',
    // "myzz": '贸易郑州',
    // "myqd": '贸易青岛',
    // "myjn": '贸易济南',
    "shwn": '贸易上海维娜',
    "FC": '辅材组',
    "YL": '原料组',
    "SPA": 'SPA组',
    "QT": '其他',
    "XZ": '徐州',
    "HF": '合肥',
    "ZZ": '郑州',
    "CD": '成都',
    "DL": '大连',
    "QD": '青岛',
    "JN": '济南',
    "ZB":'总部',
    "GC": '工厂',
    "SPAZU": 'SPA',
}

// erp 组选择
const erpGroup = [
    {
        id: "",
        key: "",
        name: '空'
    },
    {
        id: "C0057991",
        key: "G",
        name: '徐州G'
    },
    {
        id: "C0057990",
        key: "L",
        name: '徐州L'
    }
]

// 店铺操作记录
const storeOperate = [
    {
        type: 1,
        name: "终审通过",   // lbs
    },
    {
        type: 2,
        name: "审核失败",   // lbs
    },
    {
        type: 3,
        name: "初审通过",   // lbs
    },
    {
        type: 5,
        name: "创建编码",
    },
    {
        type: 6,
        name: "搬迁",   // lbs
    },
    {
        type: 7,
        name: "升级",   // lbs
    },
    {
        type: 8,
        name: "转让",   // lbs
    },
    {
        type: 9,
        name: "搬迁+升级",   // lbs
    },
    {
        type: 10,
        name: "取消",   // lbs （搬迁，升级，转让）
    },
    {
        type: 11,
        name: "编码替换",
    },
    {
        type: 12,
        name: "确认",  // (搬迁，升级，转让，升级+搬迁)
    },
    {
        type: 13,
        name: "小额订单",  // 小额订单
    },
    {
        type: 14,
        name: "属性变更",
    },
    {
        type: 15,
        name: "营业状态变更",
    },
    {
        type: 16,
        name: "保护期延长",
    },
    {
        type: 17,
        name: "组织转移",
    },
    {
        type: 18,
        name: "审核",
    },
    {
        type: 19,
        name: "回收站",
    },
    {
        type: 20,
        name: "删除",
    },
    {
        type: 21,
        name: "添加日志",
    },
    {
        type: 22,
        name: "删除日志",
    },
    {
        type: 23,
        name: "店铺门头更新",
    },
    {
        type: 24,
        name: "店铺脱保",
    },
    {
        type: 25,
        name: "信息修改",
    },
    {
        type: 26,
        name: "批量失败",
    },

]

// 店铺的最终操作记录
const sotreToStatus = [
    {
        type: 1,
        name: "出让",
    },
    {
        type: 11,
        name: "受让",
    },
    {
        type: 2,
        name: "搬迁-旧",
    },
    {
        type: 22,
        name: "搬迁-新",
    },
    {
        type: 3,
        name: "升级",
    },
    {
        type: 4,
        name: "升级搬迁-旧",
    },
    {
        type: 44,
        name: "升级搬迁-新",
    },
    {
        type: 5,
        name: "替换-旧",   // （搬迁，升级，转让）
    },
    {
        type: 55,
        name: "替换-新",
    },
]

// 抽奖等级
const RaffleLevel = {
    silver: 'silver',   // 白银
    gold: 'gold',       // 黄金
    diamond: 'diamond', // 钻石
}

// 抽奖等级列表
const raffleLevelList = [
    {
        value: RaffleLevel.silver,
        name: "白银",
    },
    {
        value: RaffleLevel.gold,
        name: "黄金",
    },
    {
        value: RaffleLevel.diamond,
        name: "钻石",
    }
]

// 五万礼包 自购产品
const giftPackProduct = [
    {
          "id": 452,
          "price": 300,
          "name": "SPA美容服(M)",
          "materialId": "ZSP030",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": true,
          "count": 2
      },
      {
          "id": 453,
          "price": 300,
          "name": "SPA美容服（L）",
          "materialId": "ZSP031",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": true,
          "count": 2
      },
      {
        "id": 733,
        "price": 300,
        "name": "SPA美容服(XL)",
        "materialId": "ZSP036",
        "materialGroup": "ZSP",
        "materialGroupName": "SPA赠品",
        "imageMain": null,
        "series": null,
        "created": "2024-04-11",
        "isEdit": true,
        "count": 2
    },
      {
          "id": 454,
          "price": 450,
          "name": "SPA美容床罩四件套(190*70)",
          "materialId": "ZSP032",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": true,
          "count": 3
      },
      {
          "id": 455,
          "price": 450,
          "name": "SPA美容床罩四件套(190*80)",
          "materialId": "ZSP033",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": true,
          "count": 4
      },
      {
          "id": 429,
          "price": 12.5,
          "name": "SPA绣花小毛巾(白色)",
          "materialId": "ZSP027",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 30
      },
      {
          "id": 430,
          "price": 12.5,
          "name": "SPA绣花小毛巾(灰色)",
          "materialId": "ZSP028",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 30
      },
      {
          "id": 431,
          "price": 40,
          "name": "SPA绣花中毛巾(白色)",
          "materialId": "ZSP029",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 25
      },
      {
          "id": 735,
          "price": 24000,
          "name": "SPA大型皮肤测试仪(礼包专用)",
          "materialId": "ZSP037",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": "",
          "series": null,
          "created": "2024-04-19",
          "isEdit": false,
          "count": 1
      },
      {
          "id": 343,
          "price": 90,
          "name": "SPA床巾",
          "materialId": "ZSP024",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 10
      },
      {
          "id": 175,
          "price": 300,
          "name": "顾客项目册",
          "materialId": "ZSP018",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 1
      },
      {
          "id": 123,
          "price": 25,
          "name": "包头巾",
          "materialId": "ZSP005",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 20
      },
      {
          "id": 125,
          "price": 85,
          "name": "SPA浴裙",
          "materialId": "ZSP009",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 20
      },
      {
          "id": 131,
          "price": 5,
          "name": "SPA拖鞋",
          "materialId": "ZSP015",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 60
      },
      {
          "id": 132,
          "price": 10,
          "name": "SPA胸牌",
          "materialId": "ZSP016",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 10
      },
      {
          "id": 133,
          "price": 10,
          "name": "SPA顾客管理卡",
          "materialId": "ZSP017",
          "materialGroup": "ZSP",
          "materialGroupName": "SPA赠品",
          "imageMain": null,
          "series": null,
          "created": "2022-11-08",
          "isEdit": false,
          "count": 50
      }
  ]

// 五万礼包 SPA赠送 - 固定产品
const giftPackFixedPresentProduct = [
    {
      "id": 569,
      "price": 380,
      "name": "赋润化妆水（院装）",
      "materialId": "PBSHZS02A",
      "materialGroup": "PBS",
      "materialGroupName": "蓓霓芬SPA",
      "imageMain": "202309061509338zgg.jpg",
      "series": "SPA系列",
      "created": "2023-02-17",
      "count": 3
    },
    {
      "id": 607,
      "price": 380,
      "name": "爽肤化妆水（院装）",
      "materialId": "PBSHZS03A",
      "materialGroup": "PBS",
      "materialGroupName": "蓓霓芬SPA",
      "imageMain": "20230906150900fr6q.jpg",
      "series": "SPA系列",
      "created": "2023-03-23",
      "count": 3
    },
    {
      "id": 571,
      "price": 420,
      "name": "（2023）蓓霓芬SPA纯净清洁霜（院装 ）",
      "materialId": "PBSHCQ02A",
      "materialGroup": "PBS",
      "materialGroupName": "蓓霓芬SPA",
      "imageMain": "20230906150919zs6m.jpg",
      "series": "SPA系列",
      "created": "2023-02-17",
      "count": 3
    },
    {
      "id": 703,
      "price": 420,
      "name": "(院装2024)蓓霓芬SPA芳香净颜按摩霜",
      "materialId": "PBSHAS05A",
      "materialGroup": "PBS",
      "materialGroupName": "蓓霓芬SPA",
      "imageMain": "20230906130956n5di.jpg",
      "series": "SPA系列",
      "created": "2024-01-10",
      "count": 3
    },
    // {
    //   "id": 489,
    //   "price": 300,
    //   "name": "玫瑰晶萃软膜粉面膜 ",
    //   "materialId": "PBSHPM04A",
    //   "materialGroup": "PBS",
    //   "materialGroupName": "蓓霓芬SPA",
    //   "imageMain": "20221202151223jsx2.jpg",
    //   "series": "SPA系列",
    //   "created": "2022-11-08",
    //   "count": 3
    // },
    {
      "id": 651,
      "price": 420,
      "name": "阿尔卑斯去角质凝胶（院装）",
      "materialId": "PBSHQN03A",
      "materialGroup": "PBS",
      "materialGroupName": "蓓霓芬SPA",
      "imageMain": "20231222091254vhnj.jpg",
      "series": "SPA系列",
      "created": "2023-05-29",
      "count": 3
    },
    {
      "id": 653,
      "price": 0,
      "name": " (2023 ) 手提袋(大) ",
      "materialId": "ZXH044",
      "materialGroup": "ZXH",
      "materialGroupName": "消耗品",
      "imageMain": null,
      "series": null,
      "created": "2023-06-07",
      "count": 100
    },
    {
      "id": 140,
      "price": 0,
      "name": "塑料袋（小）",
      "materialId": "ZXH002",
      "materialGroup": "ZXH",
      "materialGroupName": "消耗品",
      "imageMain": null,
      "series": null,
      "created": "2022-11-08",
      "count": 100
    },
    {
      "id": 139,
      "price": 0,
      "name": "塑料袋（大）",
      "materialId": "ZXH001",
      "materialGroup": "ZXH",
      "materialGroupName": "消耗品",
      "imageMain": null,
      "series": null,
      "created": "2022-11-08",
      "count": 100
    }
]

// 省
const regions = [
    {
      "id": 0,
      "name": "中国",
      "coordinate": "118.307484,31.54120"
    //   "coordinate": "108.307484,35.54120"
    },
    {
        "id": 11,
        "name": "北京市",
        "coordinate": "116.405285,39.904989"
    },
    {
        "id": 12,
        "name": "天津市",
        "coordinate": "117.190182,39.125596"
    },
    {
        "id": 13,
        "name": "河北省",
        "coordinate": "114.502461,38.045474"
    },
    {
        "id": 14,
        "name": "山西省",
        "coordinate": "112.549248,37.857014"
    },
    {
        "id": 15,
        "name": "内蒙古自治区",
        "coordinate": "111.670801,40.818311"
    },
    {
        "id": 21,
        "name": "辽宁省",
        "coordinate": "123.429096,41.796767"
    },
    {
        "id": 22,
        "name": "吉林省",
        "coordinate": "125.3245,43.886841"
    },
    {
        "id": 23,
        "name": "黑龙江省",
        "coordinate": "126.642464,45.756967"
    },
    {
        "id": 31,
        "name": "上海市",
        "coordinate": "121.472644,31.231706"
    },
    {
        "id": 32,
        "name": "江苏省",
        "coordinate": "118.767413,32.041544"
    },
    {
        "id": 33,
        "name": "浙江省",
        "coordinate": "120.153576,30.287459"
    },
    {
        "id": 34,
        "name": "安徽省",
        "coordinate": "117.283042,31.86119"
    },
    {
        "id": 35,
        "name": "福建省",
        "coordinate": "119.306239,26.075302"
    },
    {
        "id": 36,
        "name": "江西省",
        "coordinate": "115.892151,28.676493"
    },
    {
        "id": 37,
        "name": "山东省",
        "coordinate": "117.000923,36.675807"
    },
    {
        "id": 41,
        "name": "河南省",
        "coordinate": "113.665412,34.757975"
    },
    {
        "id": 42,
        "name": "湖北省",
        "coordinate": "114.298572,30.584355"
    },
    {
        "id": 43,
        "name": "湖南省",
        "coordinate": "112.982279,28.19409"
    },
    {
        "id": 44,
        "name": "广东省",
        "coordinate": "113.280637,23.125178"
    },
    {
        "id": 45,
        "name": "广西壮族自治区",
        "coordinate": "108.320004,22.82402"
    },
    {
        "id": 46,
        "name": "海南省",
        "coordinate": "110.33119,20.031971"
    },
    {
        "id": 50,
        "name": "重庆市",
        "coordinate": "106.504962,29.533155"
    },
    {
        "id": 51,
        "name": "四川省",
        "coordinate": "104.065735,30.659462"
    },
    {
        "id": 52,
        "name": "贵州省",
        "coordinate": "106.713478,26.578343"
    },
    {
        "id": 53,
        "name": "云南省",
        "coordinate": "102.712251,25.040609"
    },
    {
        "id": 54,
        "name": "西藏自治区",
        "coordinate": "91.132212,29.660361"
    },
    {
        "id": 61,
        "name": "陕西省",
        "coordinate": "108.948024,34.263161"
    },
    {
        "id": 62,
        "name": "甘肃省",
        "coordinate": "103.823557,36.058039"
    },
    {
        "id": 63,
        "name": "青海省",
        "coordinate": "101.778916,36.623178"
    },
    {
        "id": 64,
        "name": "宁夏回族自治区",
        "coordinate": "106.278179,38.46637"
    },
    {
        "id": 65,
        "name": "新疆维吾尔自治区",
        "coordinate": "87.617733,43.792818"
    }
]

  /** ============================ function ================================**/
  
const getOssPath = (type, data) => {
    let url = ""
    switch (type) {
      case 11:  // 店铺操作日志文件 data 是室长登录id
        url = `/public/${data}/file_`;
        break;
      case 12:  // 店铺图片 data 是室长登录id
        url = `/public/${data}/img_`;
        break;
    case 13:  // 店铺升级文件 data 是室长登录id
        url = `/public/${data}/file_up_`;
        break;
    }
    return url
  }

// 获取对应 area 的名称
const getAreaName = (value) => {
    let list = areaList
    for (const i in list) {
        let item = list[i]
        if (item.value == value) {
            return item.name
        }
    }
    return '-'
}

// 获取销售分析区域名称
const getSalesAreaName = (value) => {
    return salesArea[value] ?? ''
}

// 获取对应 抽奖登记 的名称
const getRaffleLevelName = (value) => {
    let list = raffleLevelList
    for (const i in list) {
        let item = list[i]
        if (item.value == value) {
            return item.name
        }
    }
    return '-'
}

const fixed = {
    agentCreateErpId,
    organize,
    sotrePropertyList,
    teamList,               // 签约公司，分配团队、组
    areaList,               // 地区
    erpGroup,               // erp 组选择
    storeOperate,
    sotreToStatus,
    giftPackProduct,
    giftPackFixedPresentProduct,
    regions,                // 省
    RaffleLevel,            // 抽奖等级
    raffleLevelList,        // 抽奖等级列表

    // 方法
    getAreaName,
    getSalesAreaName,
    getOssPath,
    getRaffleLevelName,     // 抽奖登记获取名称
};
  
export default fixed;